
/* HTMLICON BARS */
.htmlicon-bars {
    width:50px;
    height:50px;
    position: absolute;
    top:5px;
    right:5px;
    cursor:pointer;
}
.htmlicon-bars::before,
.htmlicon-bars span,
.htmlicon-bars::after {
    width:25px;
    height:2px;
    background:rgba(0,0,0,0.5);
    position: absolute;
    lefT:12px;
    transition:0.3s;
}
.htmlicon-bars span:nth-child(1) { top:17px; }
.htmlicon-bars span:nth-child(2) { top:25px; }
.htmlicon-bars span:nth-child(3) { top:32px; }

input:checked + nav {
    .htmlicon-bars span:nth-child(1) { 
        top:25px;
        -webkit-transform:rotate(45deg);
        -khtml-transform:rotate(45deg);
        -moz-transform:rotate(45deg);
        -ms-transform:rotate(45deg);
        -o-transform:rotate(45deg);
        transform:rotate(45deg);
        background:rgba(0,0,0,1);
    }
    .htmlicon-bars span:nth-child(2) { 
        left:25px;
        width:0;
        background:rgba(0,0,0,1);
    }
    .htmlicon-bars span:nth-child(3) { 
        top:25px;
        -webkit-transform:rotate(-45deg);
        -khtml-transform:rotate(-45deg);
        -moz-transform:rotate(-45deg);
        -ms-transform:rotate(-45deg);
        -o-transform:rotate(-45deg);
        transform:rotate(-45deg);
        background:rgba(0,0,0,1);
    }
}