@import 'variables';
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700';
body {
    font-family:"Roboto", sans-serif;
    font-size: 18px;
    letter-spacing:0.3pt;
    font-weight:300;
    line-height:160%;
    color:#444;
}

::selection, ::-moz-selection {
  background: $color2;
    color:$color3;
}

.contentbox > *:nth-child(1), blockquote > *:nth-child(1) {
    margin-top:0;
}

a {
    color:$color5;
    text-decoration: none;
}
p {
    margin: 20px 0;
}
hr {
    bordeR:0;
    height:1px;
    displaY:block;
    background:rgba(0,0,0,0.1);
    margin:10px 0;
}
h1, h2, h3, h4, h5, h6 {
    line-height:120%;
}
h1 {
    font-size:38px;
    font-weight:300;
    margin:30px 0 20px;
}
h2 {
    font-size:32px;
    font-weight:300;
    margin:20px 0 15px ;
}
h3 {
    font-size:26px;
    font-weight:300;
    margin:20px 0 10px ;
}
h4 {
    font-size:22px;
    font-weight:400;
    margin:20px 0 10px ;
}
h5 {
    font-size:18px;
    font-weight:500;
    margin:20px 0 10px ;
}
h6 {
    font-size:16px;
    font-weight:500;
    margin:20px 0 10px;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    opacity:0.8;
    font-weight:300;
    font-size:75%;
}


p, ul, ol, li {
    + h2 {
        margin-top: 100px;
    }

    + h3 {
        margin-top: 100px;
    }
}

mark { background:rgba(203, 173, 92, 0.2); }
strong, .text-strong { font-weight:bolder; }
small { font-size:13px; }
abbr { cursor:help; }
.text-lead { font-size:19px; }
.text-lowercase { text-transform: lowercase; }
.text-uppercase { text-transform: uppercase; }
.text-capitalize { text-transform:capitalize; }
.text-left { text-align: left }
.text-right { text-align:right }
.text-center { text-align: center }
.text-justify { text-align: justify }
.text-dropcap:first-letter {
    font-size:370%;
    font-weight:400;
    display:block;
    float:left;
    margin-top:2px;
    margin-right:5px
}
.text-hero {
   font-size:64px;
   line-height:64px;
    font-weight:700;
    color:$color2;
}

blockquote {
    margin:10px 0;
    padding:20px 20px;
    border-left:4px solid #ddd;
    font-style: italic;
}
blockquote.text-right {
    margin:10px 0;
    padding:10px 20px;
    border-left:0px solid #ddd;
    border-right:4px solid #ddd;
}
blockquote.colored { border-color:$color4; }
blockquote.coloredsec { border-color:$color2; }

ul li { list-style:disc; }
ul.unstyled { padding-left:25px; }
ul.unstyled li { list-style:none; }

