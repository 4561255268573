* {
    box-sizing: border-box;
}

.grid_12, .grid_11, .grid_10, .grid_09, .grid_08, .grid_07, .grid_06, .grid_05, .grid_04, .grid_03, .grid_02, .grid_01 {
    width:100%;
    floaT:none;
    &.padding {
        padding:10px;
    }
    &.vpadding {
        padding:0 10px;
    }
    &.hpadding {
        padding:10px 0;
    }
}


@media (min-width:420px) {
    .grid_12, .grid_11, .grid_10, .grid_09, .grid_08, .grid_07, .grid_06, .grid_05, .grid_04, .grid_03, .grid_02, .grid_01 {
        width:100%;
        floaT:none;
    }
}




@media (min-width:1024px) {
    .grid_12, .grid_11, .grid_10, .grid_09, .grid_08, .grid_07, .grid_06, .grid_05, .grid_04, .grid_03, .grid_02, .grid_01 {
        floaT:left;
        &.padding {
            padding:20px;
        }
        &.vpadding {
            padding:0 20px;
        }
        &.hpadding {
            padding:20px 0;
        }
    }
.grid_12 { width: 100% }
.grid_11 { width: 100%/12*11 }
.grid_10 { width: 100%/12*10 }
.grid_09 { width: 100%/12*9 }
.grid_08 { width: 100%/12*8 }
.grid_07 { width: 100%/12*7 }
.grid_06 { width: 100%/12*6 }
.grid_05 { width: 100%/12*5 }
.grid_04 { width: 100%/12*4 }
.grid_03 { width: 100%/12*3 }
.grid_02 { width: 100%/12*2 }
.grid_01 { width: 100%/12*1 }
}

