@import 'variables';
input[type="text"],input[type="password"],input[type="email"],input[type="number"] {
    border:none;
    border:1px solid rgba(0,0,0,0.2);
    border-radius:4px;
    padding:10px 20px;
    margin:10px 0;
    font-size:15px;
    font-weight:400;
    color:#555;
    transition:0.3s;
    display:block;
    width:100%;
}
input[type="text"]:focus,input[type="password"]:focus,input[type="email"]:focus,input[type="number"]:focus {
    border:1px solid $color5;
}

select {
    border:none;
    border:1px solid rgba(0,0,0,0.2);
    border-radius:4px;
    padding:10px 20px;
    margin:10px 0;
    font-size:15px;
    font-weight:400;
    color:#555;
    transition:0.3s;
    display:block;
    width:100%;
}

label.checkbox {
    cursor: pointer;
    display:inline-block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight:400;
}
label.checkbox input {
    display:none;
}
label.checkbox span {
    position: relative;
    width:17px;
    height:17px;
    display:inline-block;
    border:1px solid #aaa;
    border-radius:3px;
    line-height:600px;
    margin-bottom:-3px;
    transform: 0.3s;
}
label.checkbox span::before {
    content:"";
    font-family:"FontAwesome";
    position: absolute;
    left:4px;
    top:4px;
    width:100%;
    height:100%;
    text-align:center;
    line-height:90%;
    display:block;
    opacity:0;
    transition:0.3s;
    background:$color5;
    width:7px;
    height:7px;
}
label.checkbox input:checked + span {
    border:1px solid $color5;
}
label.checkbox input:checked + span::before {
    opacity:1;
}

label.radio {
    cursor: pointer;
    display:inline-block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight:400;
}
label.radio input {
    display:none;
}
label.radio span {
    position: relative;
    width:17px;
    height:17px;
    display:inline-block;
    border:1px solid #aaa;
    border-radius:50%;
    line-height:600px;
    margin-bottom:-3px;
    transform: 0.3s;
}
label.radio span::before {
    content:"";
    font-family:"FontAwesome";
    position: absolute;
    left:4px;
    top:4px;
    width:100%;
    height:100%;
    text-align:center;
    line-height:90%;
    display:block;
    opacity:0;
    transition:0.3s;
    background:$color5;
    width:7px;
    height:7px;
    border-radius:50%;
}
label.radio input:checked + span {
    border:1px solid $color5;
}
label.radio input:checked + span::before {
    opacity:1;
}

textarea {
    border:none;
    border:1px solid rgba(0,0,0,0.2);
    border-radius:4px;
    padding:10px 20px;
    margin:10px 0;
    font-size:15px;
    font-weight:400;
    color:#555;
    transition: border 0.3s;
    display:block;
    width:100%;
    max-width:100%;
    min-width:100%;
}
textarea:focus {
    border:1px solid $color5;
}
input.passwd { 
    -webkit-text-security: disc;
}



.ui-slider + input[type="text"] {
    display: block !important;
    width: 37px !important;
    text-align: center !important;
    padding: 0 !important;
    height: 22px;
    font-size: 75%;
    margin: 5px 0;
}