@import 'variables';
.button-flat, .button-raised{
    display:inline-block;
    border:none;
    font-size:16px;
    font-weight:500;
    margin:10px 0;
    padding:10px 20px;
    border-radius:4px;
    transition:0.2s;
    cursor:pointer;
    position: relative;
    overflow:hidden;
}
.roundbutton-flat, .roundbutton-raised {
    width:45px;
    height:45px;
    border-radius: 50%;
    display:inline-block;
    line-height:43px;
    text-align: center;
    font-size:18px;
    margin:10px 0;
    position: relative;
    overflow:hidden;
    transition:0.2s;
    cursor:pointer;
}
.button-flat, .roundbutton-flat  {
    background:none;
    color:$color5;
    box-shadow: 0 0 4px -3px $color5;
    
}
.button-raised, .roundbutton-raised  {
    background:$color5;
    color:#fff;
}
.button-flat.small, .button-raised.small {
    font-size:14px;
}
.button-flat.big, .button-raised.big {
    font-size:18px;
    line-height:48px;
}
.roundbutton-flat.small, .roundbutton-raised.small {
    font-size:14px; 
    width:32px;
    height:32px;
    line-height:32px;
}
.roundbutton-flat.big, .roundbutton-raised.big {
    font-size:22px;
    width:58px;
    height:58px;
    line-height:58px;
}
.button-raised.inactive, .roundbutton-raised.inactive  {
    background:#eee;
    color:#999;
    cursor:not-allowed;
    pointer-events: none;
}
.button-flat.inactive, .roundbutton-flat.inactive  {
    color:#999;
    cursor:not-allowed;
    pointer-events: none;
    box-shadow: 0 0 0 2px #ccc;
    background:#eee;
}

.button-raised:hover::after,
.roundbutton-raised:hover::after,
.button-raised:active::after,
.roundbutton-raised:active::after {
    background:rgba(255,255,255,1);
    color:#fff;
    content:"";
    animation-name:buttonraisedhover;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    display:block;
    position: absolute;
    left:10%;
    top:10%;
    transform: translate(-50%,-50%);
    border-radius:50%;
    overflow: hidden;
    width:0px;
    height:0px;
}

@keyframes buttonraisedhover {
    from {
        width:0;
        height:0;
        opacity:0;
    }
    50% {
        opacity:0.2;
    }
    to {
        width:500px;
        height:500px;
        opacity:0.1;
    }
}

.button-flat:hover,.roundbutton-flat:hover,.button-flat:active,.roundbutton-flat:active {
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    background:#fff;
}